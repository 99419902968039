$heading-font: 'Red Hat Display', sans-serif;
$body-font: 'Red Hat Text', sans-serif;
$green1:#209573;
$green2:#70B692;
$green3:#367E3E;

h1, h2, h3, h4, h5, h6, .menu  {
	font-family: $heading-font;
	line-height: 1.3;
	font-weight: 400;
}
body {
	font-family: $body-font;
    margin:0; padding:0;
}

//HEADER & FOOTER SPACING
header, footer {
	padding:18px;
}

//HOVER UNDERLINE LINK
a.link, a.link:visited {
	text-decoration: none;
	position: relative;

	
	&:after {
		content: '';
		height: 3px;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0%;
		background: #209573;
		transition: 0.2s;
	}

	&:hover:after {
		width: 100%;
	}	
}

//NAVIGATION

header {
	.menu {
		text-transform:uppercase;
		font-weight:700;
		font-size:18px;
		margin-top:10px;

		 @include breakpoint(medium) { font-size:24px; text-align:right; }

		a { padding:3px 0; @include breakpoint(medium) { padding:0; } }

	}

	button { color: black; }
}


//CAPTIONS

cite {
	font-size:11px;
	font-style:normal!important;
	color:#333;
	padding-bottom:10px;
	padding-left:10px;
}

cite::before {
	content:none;
}

//SECTIONS
$section-padding:3em;
$section-padding-medium:6em;

section.photos {
	margin:$section-padding 0;
	@include breakpoint(medium){ 
		margin:$section-padding-medium 0;
	}
}

section.photos {
	@include breakpoint(medium) {

		.cell.left { padding-right: 10px; }
		.cell.right { padding-left: 10px; }
	}

	.cell img {
		padding:10px 0;
	}
}

section.photos.top {
	margin-top:0;
	img { width:100%; height:auto;}
}


$masonry-css-column-gap: 1rem;

.masonry-css {
  column-count: 1;
  column-gap: $masonry-css-column-gap;

  @include breakpoint(400px) {
    column-count: 2;
  }

  @include breakpoint(600px) {
    column-count: 2;
    column-gap: 3rem;
  }

  @include breakpoint(800px) {
    column-count: 3;
  }

  @include breakpoint(1100px) {
    column-count: 3;
  }
}

.masonry-css-item {
  break-inside: avoid;
  margin-bottom: $masonry-css-column-gap;
}

//PROJECTS

#projects {

	padding:3rem 0;

	h5.sub {font-weight:900;margin-bottom:24px;}

	ul { margin:0; padding:0; }

	li {list-style:none;}

	.project-grid  {

		margin-bottom:36px;

		h2, h3 {
			font-size:18px;
			line-height:1.2;
		}

		h2 { text-transform:uppercase; font-weight:900; letter-spacing:.0125em; }

		h3 { 
			margin:0 0 10px 0;
			font-size:21px;
		}

		a {
			color:$green1;
			text-decoration:none;
			//border-bottom:1px dotted rgba(32,149,115,.8);
		}

		p {
			font-size:14px;
			line-height:1.4;
			margin-bottom:30px;
		}

		img { 
			width:100%; 
			height:auto; 
			padding-bottom:15px; 

			@include breakpoint (medium) { 
					padding-right:15px;
				}
			}
	}
}

footer {
	background:#ececec;
	font-size:12px;


	.menu {

		padding:12px 0;

		a {
			padding:0;
			line-height:1.4;
		}

		li {
			padding:2.5px 0;
			line-height:1.25;
		}

		li.spacer {
			padding:0;
		}
	}

	.links li {
		padding:7px 0;
	}

}

.reveal {

		div {
			padding:72px;
		}

		p {
			font-size:14px;
			line-height:1.6;
		}

		button {
			font-size:24px;
			margin:0 0 24px 24px;
		}
	}





